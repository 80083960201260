var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"curriculum-index"},[_c('banner-bg',{attrs:{"type":5}},[_c('div',{staticClass:"curriculum-index-base"},[_c('new-banner',{attrs:{"bannerList":_vm.bannerList,"height":600}})],1)]),_c('div',{staticClass:"curriculum-index-body"},[_c('div',{staticClass:"curriculum-index-title"},[_c('span',{staticClass:"title"},[_vm._v("考试真题")]),_c('span',[_vm._v("中国电子学会等级考试真题")]),(_vm.titleConfig.isInit)?_c('div',{staticClass:"switch-tab"},[_c('div',{staticClass:"tab-item",class:_vm.platform ? 'tab-item-active' : '',on:{"click":function () {
              if(_vm.platform) { return }
              _vm.platform = !_vm.platform;
              _vm.changeCourseList(2);
            }}},[_vm._v(" 平台题库 ")]),_c('div',{staticClass:"tab-item",class:_vm.selfBuild ? 'tab-item-active' : '',on:{"click":function () {
              if(_vm.selfBuild) { return }
              _vm.selfBuild = !_vm.selfBuild;
              _vm.changeCourseList(1);
            }}},[_vm._v(" 自建题库 ")]),_c('div',{staticClass:"tab-background-bar",class:_vm.platform ? 'tab-background-bar-left' : 'tab-background-bar-right'})]):_vm._e(),_c('div',{staticClass:"clear"})]),_c('Question-list',{ref:"questionList",attrs:{"isUserInfo":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }