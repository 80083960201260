<template>
  <div class="curriculum-index">
    <banner-bg :type="5">
      <div class="curriculum-index-base">
        <new-banner
          :bannerList="bannerList"
          :height="600"
        ></new-banner>
      </div>
    </banner-bg>
    <!-- <p
      class="login-tip"
      v-if="isLogin == false"
    >请先登录！</p> -->
    <div class="curriculum-index-body">
      <div class="curriculum-index-title">
        <span class="title">考试真题</span>
        <span>中国电子学会等级考试真题</span>
        <!-- <div
          class="curriculum-btn"
          v-if="titleConfig.isInit"
        >
          <el-tooltip class="item" effect="dark" :content="titleConfig.serviceLevel==1?'暂未开通此功能':''" placement="top-start">
          <div class="curriculum-block">
            <el-switch
              @change="changeCourseList(2)"
              v-model="platform"
              :disabled="titleConfig.serviceLevel==1?true:false"
              active-color="#f59a23"
            >
            </el-switch>
            <span :class="platform == true?'curriculum-span-light':'curriculum-span'">平台题库</span>
          </div>
          <div class="curriculum-block">
            <el-switch
              @change="changeCourseList(1)"
              v-model="selfBuild"
              :disabled="titleConfig.serviceLevel==1?true:false"
              active-color="#f59a23"
            >
            </el-switch>

            <span :class="selfBuild == true?'curriculum-span-light':'curriculum-span'">自建题库</span>
          </div>
          </el-tooltip>
        </div> -->

        <div class="switch-tab" v-if="titleConfig.isInit">
          <div
            class="tab-item"
            :class="platform ? 'tab-item-active' : ''"
            @click="
              () => {
                if(platform) return
                platform = !platform;
                changeCourseList(2);
              }
            "
          >
            平台题库
          </div>
          <div
            class="tab-item"
            :class="selfBuild ? 'tab-item-active' : ''"
            @click="
              () => {
                if(selfBuild) return
                selfBuild = !selfBuild;
                changeCourseList(1);
              }
            "
          >
            自建题库
          </div>
          <div
            class="tab-background-bar"
            :class="
              platform ? 'tab-background-bar-left' : 'tab-background-bar-right'
            "
          ></div>
        </div>

        <div class="clear"></div>
      </div>
      <Question-list
        ref="questionList"
        :isUserInfo="false"
      ></Question-list>
    </div>
  </div>
</template>

<script>
import { getBanners } from '@/api/common';
import { getUserInfo } from '@/utils/auth';
import { mapState } from 'vuex'

import Curriculums from '@/components/Curriculums';
import QuestionList from '@/components/QuestionList'
import BannerBg from '@/components/BannerBg.vue';
import NewBanner from '@/components/NewBanner.vue';

export default {
  name: 'Curriculum-index',
  components: {
    Curriculums,
    QuestionList,
    BannerBg,
    NewBanner
  },
  data() {
    return {
      path: 'paper/index',
      platform: true,
      selfBuild: false,
      bannerList: [],
    };
  },
  computed: {
    isLogin: function () {
      let userInfo = getUserInfo();
      return !!userInfo;
    },
    ...mapState({
      titleConfig: (state) => state.siteConfigs,
    })
  },
  created() {
    this.getBannerList();
  },
  methods: {
    changeCourseList(type) {
      if (type == 1) {
        this.platform = !this.selfBuild;
      } else {
        this.selfBuild = !this.platform;
      }
      this.$refs.questionList.handleChangeBank(this.platform);
    },
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'tk',
        platform: 'yqb',
        isPortal: '1'
      }
      getBanners(param).then(res => {
        if (res.success) {
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    toSystem() {
      this.$router.push({ path: `/curriculumsystem` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/curriculum-index.scss';
@import '~@/assets/css/views/question-bank.scss';

      .switch-tab {
        float: right;
        width: 120px;
        height: 30px;
        background-color: #eff3f8;
        position: relative;
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        .tab-item {
          position: relative;
          z-index: 999;
          width: 60px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #5c9df8;
        }
        .tab-item-active {
          color: #fff;
        }
        .tab-background-bar {
          position: absolute;
          width: 60px;
          height: 28px;
          background-color: #5c9df8;
          top: 1px;
          transition: all 0.3s;
          left: 0;
        }
        .tab-background-bar-left {
          left: 0;
        }
        .tab-background-bar-right {
          left: 60px;
        }
      }
</style>
